@if ((notificationService.notificationBarMessages$ | async ); as alertMsgs) {
  @if (dataAvailability?.isValid && alertMsgs?.length > 0) {
    <div class="alert-bar">
      @for (msg of alertMsgs; track msg; let i = $index) {
        <div class="alert-bar__message" [ngClass]="msg.severity">
          <svg-icon [key]="AlertSeverity.Warning"></svg-icon>
          <p [innerHTML]="msg.htmlText"></p>
          @if (msg.contactSupportEnabled) {
            <button type="button" (click)="openContactSupportModal()" class="btn btn-link">Contact support</button>
          }
          @if (msg.link) {
            <button type="button" (click)="navigateByRoute(msg.link.route)" class="btn btn-link">{{msg.link.text}}</button>
          }
        </div>
      }
    </div>
  }
}
