<div class="no-connection-page-content">
  <svg-icon key="no-connection" fontSize="56px" class="m-b-16"></svg-icon>
  <p class="no-connection-title m-b-48">The connection to your data in {{getPlatformString()}} has not been established yet.</p>

  @if (hasUiPathPlatform()) {
    <div class="panel">
      <div class="panel-section column">
        <p class="m-b-12">Integrating Pointee with UiPath is simple and only takes a few minutes.</p>
        <div class="m-b-24">
          <button class="btn btn-link" (click)="downloadUiPathManual()">Learn how</button>
        </div>
        <p class="light no-margin">Please note that at least one item or a job has to finish before you start seeing your data in Pointee.</p>
      </div>
    </div>
  }

  @if (hasBluePrismPlatform()) {
    <div class="panel">
      <div class="panel-section column">
        <p class="m-b-12">Need to export data from Blue Prism to Pointee?</p>
        <button class="btn btn-link" (click)="downloadBluePrismApp()">Download our data utility</button>
      </div>
    </div>
  }

  <button class="btn btn-link" (click)="openContactSupportModal()">Contact support</button>
</div>
