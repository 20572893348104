<shared-modal [modalTitle]="'Notifications settings'" [showFooter]="true">
  <div class="body-content" modalBody>
    <p><b>Notify me about issues</b></p>
    @if (configuration) {
      <table mat-table class="fixed m-b-24" [dataSource]="configuration.notifications">
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let notification">{{ notification.severity }}</td>
        </ng-container>
        <ng-container matColumnDef="in-app">
          <th mat-header-cell *matHeaderCellDef class="w-col-2">In-app</th>
          <td mat-cell *matCellDef="let notification" class="w-col-2">
            <mat-checkbox [checked]="notification.inAppEnabled" (change)="notification.inAppEnabled = !notification.inAppEnabled"></mat-checkbox>
          </td>
        </ng-container>
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef class="w-col-2">Email</th>
          <td mat-cell *matCellDef="let notification" class="w-col-2">
            <mat-checkbox [checked]="notification.emailEnabled" (change)="notification.emailEnabled = !notification.emailEnabled"></mat-checkbox>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let data; columns: columnsToDisplay"></tr>
      </table>
    }
  </div>
  <div class="modal-footer-buttons" modalFooter>
    <button type="button" class="btn btn-cancel" (click)="close()">Cancel</button>
    <button type="submit" class="btn btn-primary" (click)="save()">Save</button>
  </div>
</shared-modal>
