import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { ChangePasswordModalComponent } from 'src/app/shared/components/_modals/change-password-modal/change-password-modal.component';
import { DeviceType } from 'src/app/shared/enums/device-type.enum';
import { Permission } from 'src/app/shared/enums/generated.enums';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { OrganizationService } from 'src/app/shared/services/organization.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { Global } from 'src/app/shared/types/global.type';
import { LoggedUser } from 'src/app/shared/types/logged-user.type';
import { NotificationSettingsModalComponent } from '../../shared/components/_modals/notification-settings-modal/notification-settings-modal.component';
import { MenuService } from '../../shared/services/menu.service';
import { MenuItem, MenuSettings } from '../../shared/types/menu-section.type';
import { OrganizationSelectComponent } from '../organization-select/organization-select.component';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
  menuSettings$: Observable<MenuSettings> = this.menuService.menuSettings$.pipe(
    tap(m => {
      this.allowExpandMenu = m.isMenuExpanded !== false;
      this.isMenuExpanded = m.isMenuExpanded ?? this.isMenuExpanded;
    })
  );

  user: LoggedUser;
  isScrolled = false;
  isNextScrolled = false;
  allowExpandMenu = true;
  private displayScrollbar = false;
  readonly minScroll = 8;

  get isMobile(): boolean {
    return Global.isMobile;
  }

  get parentRoute$(): Observable<string[] | null> {
    return this.menuSettings$.pipe(map(m => m.sections[0]?.parentRoute));
  }

  get firstLabel$(): Observable<string | null> {
    return this.menuSettings$.pipe(map(m => m.sections[0]?.label));
  }

  get rootRoute(): string[] {
    return this.navigationService.getRootRoute();
  }

  get isMenuExpanded(): boolean {
    return this.storageService.isMenuExpanded;
  }

  set isMenuExpanded(expanded: boolean) {
    this.storageService.isMenuExpanded = expanded;
  }

  get allowOrganizationSelect(): boolean {
    // depends on the number of organizations for the user
    return this.organizationService.organizations?.length > 1;
  }

  get isUserPasswordLoginEnabled(): boolean {
    return this.organizationService.organizationConfiguration?.isUserPasswordLoginDisabled != true;
  }

  get isSystemAdmin(): boolean {
    return this.authService.isSystemAdmin;
  }

  get areNotificationSettingsEnabled(): boolean {
    return this.authService.hasPermission(Permission.MetricAlerts);
  }

  @ViewChild('menuBodyContainer', { static: false }) set menuBodyContainer(element: ElementRef<HTMLElement>) {
    this.isNextScrolled = element.nativeElement.offsetHeight < element.nativeElement.children[0].scrollHeight - this.minScroll;
    this.cdr.markForCheck();
  }

  constructor(
    private menuService: MenuService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private authService: AuthService,
    private navigationService: NavigationService,
    private storageService: StorageService,
    private organizationService: OrganizationService
  ) {
    Global.deviceTypeHasChanged$.subscribe(() => {
      if (Global.deviceType === DeviceType.phone) {
        this.isMenuExpanded = false;
      }
      this.cdr.markForCheck();
    });
    this.user = this.authService.user;
  }

  toggleMenuExpanded(): void {
    this.isMenuExpanded = !this.isMenuExpanded;
    if (!this.allowExpandMenu) {
      this.isMenuExpanded = false;
    }
    this.cdr.markForCheck();
  }

  onBodyContainerScroll(event: Event): void {
    const target = event.target as HTMLElement;
    // header scroll shadow
    this.isScrolled = target.scrollTop > this.minScroll;
    // footer scroll shadow
    this.isNextScrolled = target.offsetHeight + target.scrollTop <= target.scrollHeight - this.minScroll;
    if (this.isScrolled !== this.displayScrollbar) {
      this.displayScrollbar = this.isScrolled;
    }
  }

  displayOrganizationSelect(): void {
    this.modalService.open(OrganizationSelectComponent);
  }

  changePassword(): void {
    this.modalService.open(ChangePasswordModalComponent);
  }

  async signOut(): Promise<void> {
    await this.navigationService.navigateToLogin('User logged out manually', false, false);
  }

  async gotoDesignSystemOverview(): Promise<void> {
    await this.navigationService.navigateToDesignSystemOverview();
  }

  async gotoAdministrationPage(): Promise<void> {
    await this.navigationService.navigateToAdminOrganization(this.organizationService.selectedOrganization?.name ?? '');
  }

  async reloadPage(route: string[]): Promise<void> {
    await this.navigationService.navigateByRoute(route, true);
  }

  openNotificationSettings(): void {
    this.modalService.open(NotificationSettingsModalComponent);
  }

  isBadgeVisible(menuItem: MenuItem, badgeNumber: number): boolean {
    const hideWhenOnPage = menuItem.hideBadgeWhenOnPage === true && this.navigationService.isOnPage(menuItem.pageType);
    return badgeNumber > 0 && !hideWhenOnPage;
  }
}
